import { DOCUMENT } from "@angular/common";
import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() dataLayer?: Object;

  @HostBinding("class.bg-black-translucent") classBgBlackTranslucent = true;
  @HostBinding("class.glass") classGlass = true;
  @HostBinding("hidden") hidden = true;

  public subscription!: Subscription;

  constructor(
    public modalService: ModalService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit(): void {
    this.subscription = this.modalService.openClose$.subscribe((obj) => {
      this.hidden = !obj[this.id];
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public fechar() {
    (this.document.body.style.overflow as any) = null;
    this.hidden = true;
    this.modalService.openClose[this.id] = false;
    this.removeHash();
  }

  removeHash() {
    var scrollV,
      scrollH,
      loc = globalThis?.window?.location;
    if ("pushState" in history)
      history.pushState("", document.title, loc.pathname + loc.search);
    else {
      // Prevent scrolling by storing the page's current scroll offset
      scrollV = document.body.scrollTop;
      scrollH = document.body.scrollLeft;

      loc.hash = "";

      // Restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  }

  public exibiu() {
    (this.document.body.style.overflow as any) = "hidden";
  }
}
