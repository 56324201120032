import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalStateService {
  public isMobile = false;
  public isMobileChanges = new BehaviorSubject<boolean>(false);

  public changeToMobile(state: boolean): void {
    this.isMobileChanges.next(state);
    this.isMobile = state;
  }
}
