import { DOCUMENT } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
  BehaviorSubject,
  Subscription,
  filter,
  map,
  shareReplay,
  switchMap,
} from "rxjs";
import { GlobalStateService } from "src/app/services/global-state.service";
import { HttpStateService } from "src/app/services/http.state.service";
import { MenuService } from "src/app/services/menu.service";
import { PageService } from "src/app/services/page.service";
import { TradutorService } from "src/app/services/tradutor.service";
import { environment } from "src/environments/environment";
import { BreadItem } from "src/models/BreadItem";
import { CustomLogo } from "src/models/CustomLogo";
import { Raiz } from "src/models/Menus";
import { StrapiRetornoUnico } from "src/models/strapi_retorno";
import { IHeader, ILink } from "src/models/zeus";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public customLogoList: CustomLogo[] = [
    { path: "seminovos", image: "/assets/images/logo-seminovos.jpg" },
  ];
  public customLogo$;
  public breadcrumb$;
  public isMobile!: boolean;
  public subscription!: Subscription;
  public routerSubscription!: Subscription;

  constructor(
    private tradutorService: TradutorService,
    private httpStateService: HttpStateService,
    private menuService: MenuService,
    private router: Router,
    private pageService: PageService,
    private globalStateService: GlobalStateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isMobile = this.globalStateService.isMobile;

    const _breadcrumb$ = new BehaviorSubject(
      this.router.routerState.snapshot.url
    );
    const _customLogo$ = new BehaviorSubject(
      this.router.routerState.snapshot.url
    );

    this.routerSubscription = this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map((navigationEnd: NavigationEnd) => {
          return navigationEnd.urlAfterRedirects;
        }),
        shareReplay()
      )
      .subscribe((url) => {
        _breadcrumb$.next(url);
        _customLogo$.next(url);
      });

    this.customLogo$ = _customLogo$.pipe(
      map((url) => {
        return this.customLogoList.find((i) =>
          url.replace(/^\//gi, "").startsWith(i.path.replace(/^\//gi, ""))
        )?.image;
      })
    );

    this.breadcrumb$ = _breadcrumb$.pipe(
      map((url) => {
        const listaFinal: BreadItem[] = [];

        let lista: BreadItem[] = url
          .split("#")[0]
          .split("/")
          .filter((x) => x)
          .map((x) => {
            try {
              const decodedText = decodeURI(x);
              return { url: x, texto: decodedText };
            } catch {
              return { url: x, texto: x };
            }
          });

        listaFinal.push({ url: "", texto: "Inicio" });

        lista.forEach((elem, index, arr) => {
          const ultimaUrl = listaFinal[listaFinal.length - 1].url;
          const ehUltimoParametroDaUrl = arr.length - 1 == index;
          const params = ehUltimoParametroDaUrl
            ? Object.fromEntries(new URLSearchParams(elem.url.split("?")[1]))
            : undefined;

          listaFinal.push({
            url: ultimaUrl + "/" + elem.url.split("?")[0],
            texto: elem.texto.split("?")[0].replace(/\-/gi, " "),
            params,
          });
        });
        return listaFinal;
      })
    );
  }

  public ngOnInit() {
    this.subscription = this.globalStateService.isMobileChanges.subscribe(
      (actualState) => {
        this.isMobile = actualState;
      }
    );
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  public header$ = this.tradutorService.locale$
    .pipe(
      switchMap((idioma) => {
        const url =
          environment.API_URL +
          environment.API_PATH_HEADER +
          "?populate=deep&locale=" +
          idioma;
        return this.httpStateService.get<StrapiRetornoUnico<IHeader>>(url);
      })
    )
    .pipe(map((x) => x.data.attributes));

  public menu$ = this.menuService.menuHeader$.pipe();

  public changeLang(idioma: string): void {
    this.tradutorService.trocarIdioma(idioma, "usuário");
  }

  public menuToILink(sub: Raiz): Partial<ILink> {
    return {
      href: sub.attributes.url,
      target: sub.attributes.target as any,
      title: sub.attributes.title,
    };
  }

  public onToggleMobileMenu(): void {
    this.menuService.toggleMenuHeaderMobile();
  }

  public firstBlockHasCaroussel(): void {
    this.pageService.temUmCarrosselNoPrimeiroBlocoDaPrimeiraSection;
  }

  public isMobileMenuOpen(): boolean {
    return this.menuService.menuHeaderMobileOpen;
  }
}
