import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  SempreNovoService,
  VeiculosPorModeloResponse,
} from "src/app/services/sempre-novo.service";

interface Image {
  url: string;
  alt: string;
}

type VeiculoSempreNovo =
  VeiculosPorModeloResponse["veiculos"]["results"][number] & {
    whatsAppLink: string;
    veiculoLink: string;
    precoMin: number;
    minContrato: string;
    kmFranquia: number;
  };

@Component({
  selector: "app-block-modelo-detalhe",
  templateUrl: "./block-modelo-detalhe.component.html",
  styleUrls: ["./block-modelo-detalhe.component.scss"],
})
export class BlockModeloDetalheComponent implements OnInit, OnDestroy {
  public modelo!: string;

  public modeloSubscription!: Subscription;
  public modeloDetalhe!: VeiculosPorModeloResponse;
  public veiculos: Array<VeiculoSempreNovo> = [];
  public images: Image[] = [];
  public whatsAppLink = `https://api.whatsapp.com/send?phone=5511978379385&text=Olá, tenho interesse em alugar um Sempre Novo do modelo: ${globalThis?.window?.location.href}`;
  public paginacao = { paginaAtual: 1, itensTotais: 8 };

  constructor(
    private route: ActivatedRoute,
    private sempreNovoService: SempreNovoService
  ) {}

  ngOnDestroy(): void {
    this.modeloSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.modelo = this.route.snapshot.queryParams["id"];
    this.buscarModelo();
  }

  private async buscarModelo() {
    this.modeloSubscription = this.sempreNovoService
      .listarVeiuclosPorModelo(this.modelo, this.paginacao.paginaAtual)
      .subscribe((info) => {
        this.modeloDetalhe = info;
        this.paginacao = {
          paginaAtual: info.veiculos.pagination.page,
          itensTotais: info.veiculos.pagination.total,
        };
        this.veiculos = info.veiculos.results.map((veiculo) => {
          let minContrato = "60 meses";

          if (
            veiculo.rental_plan!.fourYearRate <
            veiculo.rental_plan!.fiveYearRate
          ) {
            minContrato = "48 meses";
          }

          if (
            veiculo.rental_plan!.threeYearRate <
            veiculo.rental_plan!.fourYearRate
          ) {
            minContrato = "36 meses";
          }

          return {
            ...veiculo,
            veiculoLink: `/seminovos/produto/${veiculo.codigoVeiculo}`,
            whatsAppLink: `https://api.whatsapp.com/send?phone=5511978379385&text=Olá, tenho interesse em alugar este veículo Sempre Novo: ${globalThis?.window?.location.origin}/seminovos/produto/${veiculo.codigoVeiculo}`,
            precoMin: Math.min(
              veiculo.rental_plan?.threeYearRate || 0,
              veiculo.rental_plan?.fourYearRate || 0,
              veiculo.rental_plan?.fiveYearRate || 0
            ),
            minContrato,
            kmFranquia: veiculo.rental_plan?.kmFee || 0,
          };
        });
        console.log(this.veiculos);
        this.images = info.imagens.split(",").map((image) => {
          return {
            url: image,
            alt: this.modeloDetalhe.modelo,
          };
        });
      });
  }

  public currencyValue(value: number | undefined) {
    if (value != undefined) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return value;
  }

  public pageChanged(pagina: number): void {
    this.paginacao.paginaAtual = pagina;
    this.buscarModelo();
  }
}
