import {
  Directive,
  OnDestroy,
  Input,
  ElementRef,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[tooltip]",
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltip = ""; // The text for the tooltip to display
  @Input() delay = 50; // Optional delay input, in ms

  private myPopup?: HTMLDivElement;
  private timer?: NodeJS.Timeout;

  constructor(private el: ElementRef<HTMLElement>) {
    el.nativeElement.classList.add("tooltip-host");
  }

  public ngOnDestroy(): void {
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  @HostListener("mouseenter") onMouseEnter() {
    this.timer = setTimeout(() => {
      const boundingClientRect = this.el.nativeElement.getBoundingClientRect();
      let x = boundingClientRect.left + this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
      let y = boundingClientRect.top + this.el.nativeElement.offsetHeight + 6; // Get the bottom of the element, plus a little extra

      let tooltipWidth = 350 / 2; // tamanho máximo do tooltip
      let newPosition = x;

      if (x + tooltipWidth > globalThis?.window?.innerWidth) {
        const difference = x + tooltipWidth - globalThis?.window?.innerWidth;
        newPosition = x - difference;
      }

      this.createTooltipPopup(newPosition, y);
    }, this.delay);
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  @HostListener("scroll") onScroll() {
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  private createTooltipPopup(x: number, y: number) {
    let popup = document.createElement("div");
    popup.innerHTML = this.tooltip;
    popup.setAttribute("class", "tooltip-container");
    popup.style.top = y.toString() + "px";
    popup.style.left = x.toString() + "px";
    document.body.appendChild(popup);
    this.myPopup = popup;
  }
}
