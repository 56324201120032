export interface ProdutoPdvComMediaeFipe extends ProdutoPdv {
  precoFipe: string;
  mediaVamos: number;
}

export enum CategoriaVeiculoEnum {
  cavaloMecanico = "CAVALO MECÂNICO",
  onibus = "ÔNIBUS",
  onibusRodoviario = "ÔNIBUS RODOVIÁRIO",
  onibusUrbano = "ÔNIBUS URBANO",
  maquinasAgricolas = "MAQ. LINHA VERDE",
  caminhoes = "CAMINHÃO",
  sempreNovo = "SEMPRE NOVO",
  carreta = "CARRETA",
  abaixoTabela = "OPORTUNIDADES",
  utilitarios = "UTILITÁRIO",
  implementos = "IMPLEMENTOS",
}
export interface ProdutoPdv {
  id: number;
  codigo: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: null;
  sitemap_exclude: boolean;
  descricao?: string;
  placa: string;
  marca: string;
  codigoFipe?: string;
  categoria: string;
  modelo: string;
  eixo: string;
  ano: string;
  km: number;
  inativo: boolean;
  valor: number;
  tipo: string;
  tipoAquisicao: string;
  url: string;
  cor: string;
  implementos?: string;
  updateCode: string;
  filial: Filial;
  filial_nome: string;
  placaFinal: string;
  anoModelo: number;
  anoFabricacao: number;
  codigoVeiculo: string;
  imagens: string;
  createdBy: null;
  updatedBy: null;
  rental_plan?: RentalPlan;
  veiculo_videos: {
    link: string;
  }[];
}

export interface RentalPlan {
  id: number;
  name: string;
  threeYearRate: number;
  fourYearRate: number;
  fiveYearRate: number;
  kmFee: number;
}

export interface Imagens {
  codigo: string;
  codigoVeiculo: string;
  descricao: string;
  ordem: number;
  excluida: boolean;
  padrao: boolean;
  ultimaAtualizacao: string;
  url: string;
}

interface ListaItens {
  nome: string;
  tipoItem: string;
  tipoAvaliacao: string;
  avaliacao: number;
  observacao: string | any;
  veiculoAvaliacaoId: string;
  bloqueiaSite: boolean;
}

interface Filial {
  nome: string;
  telefone?: string;
  endereco?: string;
  cidade?: string;
  bairro?: any;
  cep?: any;
  uf: string;
  linkGoogleMaps?: any;
  empresaEnum: number;
}
