import { Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProdutoPdvComMediaeFipe } from "src/models/ProdutoPdv";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { DataLayerService } from "src/app/services/data-layer.service";
import { pdvService } from "src/app/services/pdv.service";
import { catchError, of, tap } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

@Component({
  selector: "app-block-product-description",
  templateUrl: "./block-product-description.component.html",
  styleUrls: ["./block-product-description.component.scss"],
})
export class BlockProductDescriptionComponent {
  loadingRecommended = false;
  public veiculo: ProdutoPdvComMediaeFipe = {
    id: 127297,
    codigo: "",
    createdAt: new Date(),
    updatedAt: new Date(),

    publishedAt: null,
    sitemap_exclude: false,
    descricao: "",
    marca: "",
    codigoFipe: "",
    categoria: "",
    modelo: "",
    eixo: "",
    ano: "",
    km: 0,
    inativo: false,
    valor: 0,
    tipo: "",
    tipoAquisicao: "",
    url: "",
    cor: "",
    implementos: "",
    placa: "",
    updateCode: "",
    placaFinal: "",
    anoModelo: 2021,
    anoFabricacao: 2021,
    codigoVeiculo: "",
    imagens: "",
    filial: {
      uf: "",
      cep: null,
      nome: "",
      bairro: null,
      cidade: "",
      endereco: "",
      telefone: "null",
      linkGoogleMaps: null,
      empresaEnum: 0,
    },
    filial_nome: "",
    createdBy: null,
    updatedBy: null,
    precoFipe: "",
    veiculo_videos: [],
    mediaVamos: 0,
  };

  recommendedVehicles: ProdutoPdvComMediaeFipe[] = [];
  veiculoVideoUrl = "";
  public codigoVeiculo!: string;
  public imagens: string[] = [];
  public thumbsSwiper: any;
  agendamentoEmbed = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pdvService: pdvService,
    public dataLayerService: DataLayerService,
    public modalService: ModalService,
    private httpService: HttpClient
  ) {}

  clickCard() {
    globalThis.window.location.reload();
  }

  ngOnInit(): void {
    this.codigoVeiculo = this.route.snapshot.url[2].path;

    this.pdvService
      .buscarVeiculo(this.codigoVeiculo)
      .pipe(
        catchError((err: any) => {
          err;
          this.router.navigateByUrl("/seminovos");
          return of();
        })
      )
      .subscribe((veiculoPdv) => {
        this.veiculo = veiculoPdv;
        this.veiculoVideoUrl = this.veiculo.veiculo_videos?.[0]?.link ?? "";
        this.imagens = veiculoPdv.imagens
          ? veiculoPdv.imagens.split(",")
          : ["/assets/images/veiculo-preparacao.jpeg"];

        if (this.veiculo.id)
          this.httpService
            .get("https://api.ipify.org?format=json")
            .subscribe((data: any) => {
              const { ip } = data;
              this.loadingRecommended = true;
              this.httpService
                .get<ProdutoPdvComMediaeFipe[]>(
                  environment.API_URL +
                    "/api/produto-visita-logs/recommendation/" +
                    this.veiculo.id
                )
                .subscribe(
                  (data: ProdutoPdvComMediaeFipe[]) => {
                    this.loadingRecommended = false;
                    this.recommendedVehicles = data || [];
                  },
                  () => {
                    this.loadingRecommended = false;
                  }
                );

              this.httpService
                .post(environment.API_URL + "/api/produto-visita-logs", {
                  data: {
                    ip,
                    veiculo: this.veiculo.id,
                  },
                })
                .subscribe();
            });
      });
  }

  public swiperConfigMobile: any = {
    slidesPerView: "auto",
    spaceBetween: 24,
    breakpoints: {
      0: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 3,
      },
      1366: {
        perViewDesktop: 4,
      },
    },
  };

  public currencyValue(value: number | undefined) {
    if (value != undefined) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return value;
  }

  agendarVideoChamada() {}
}
