import { Location } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { DataLayerService } from "src/app/services/data-layer.service";
import { LeadService } from "src/app/services/lead.service";
import { IBlockNewsletter } from "src/models/zeus";
import { environment } from "src/environments/environment";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-block-news-letter",
  templateUrl: "./block-news-letter.component.html",
  styleUrls: ["./block-news-letter.component.scss"],
})
export class BlockNewsLetterComponent implements OnInit {
  @Input() identifier = "newsletter/" + this.location.path(false).split("?")[0];
  @Input() block!: IBlockNewsletter;
  public envioStatus?:
    | "carregando"
    | "enviado"
    | "enviado-newsletter"
    | "erro"
    | undefined;

  constructor(
    public dataLayerService: DataLayerService,
    private location: Location,
    private http: HttpClient
  ) {}

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl("", Validators.compose([Validators.required])),
    email: new UntypedFormControl(
      "",
      Validators.compose([Validators.required, Validators.email])
    ),
    interessecaminhao: new UntypedFormControl(""),
    interessecavalomecanico: new UntypedFormControl(""),
    interesseimplementos: new UntypedFormControl(""),
    interesseonibus: new UntypedFormControl(""),
    interesselinhaverde: new UntypedFormControl(""),
    interesselinhaamarela: new UntypedFormControl(""),
  });

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);
  }

  public onSubmit() {
    const f = this.form.value;

    const pathname = globalThis?.window?.location.pathname;
    if (pathname.startsWith("/")) {
      const newUrl = pathname.substr(1);
      const hash = globalThis?.window?.location.hash;
      this.identifier = `newsletter-${newUrl}${hash}`;
    }

    let dados = {
      name: f.nome,
      email: f.email,
      interessecaminhao: f.interessecaminhao,
      interessecavalomecanico: f.interessecavalomecanico,
      interesseimplementos: f.interesseimplementos,
      interesseonibus: f.interesseonibus,
      interesselinhaverde: f.interesselinhaverde,
      interesselinhaamarela: f.interesselinhaamarela,
      conversion_identifier: this.identifier,
      token_rdstation: environment.RD_PUBLIC_TOKEN,
    };

    if (this.form.valid) {
      this.envioStatus = "carregando";
      this.http
        .post(
          `https://api.rd.services/platform/conversions?api_key=${environment.API_KEY_RD_STATION}`,
          {
            event_type: "CONVERSION",
            event_family: "CDP",
            payload: dados,
          }
        )
        .subscribe({
          next: () => {
            this.envioStatus = "enviado-newsletter";
            this.dataLayerService.formSucesso(this.identifier);
          },
          error: () => {
            this.envioStatus = "erro";
          },
        });
    }
  }
}
