import { Location } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import cnpjMask from "src/app/helpers/mask/cnpjMask";
import phoneNumberMask from "src/app/helpers/mask/phoneNumberMask";
import { DataLayerService } from "src/app/services/data-layer.service";
import { LeadService } from "src/app/services/lead.service";
import { environment } from "src/environments/environment";
import { Status } from "src/models/Status";
import { IBlockContactForm } from "src/models/zeus/block/block-contact-form";

@Component({
  selector: "app-form-supplier-registry",
  templateUrl: "./form-supplier-registry.component.html",
  styleUrls: ["./form-supplier-registry.component.scss"],
})
export class FormSupplierRegistryComponent implements OnInit, OnDestroy {
  @Input() block!: IBlockContactForm;
  @Input() identifier =
    this.block?.identifier ?? this.location.path(false).split("?")[0];

  public sendStatus?: Status;
  public subscription!: Subscription;

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl("", Validators.compose([Validators.required])),
    razaosocial: new UntypedFormControl(
      "",
      Validators.compose([Validators.required])
    ),
    cnpj: new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.maxLength(18),
        Validators.minLength(14),
      ])
    ),
    telefone: new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(14),
      ])
    ),
    email: new UntypedFormControl(
      "",
      Validators.compose([Validators.required, Validators.email])
    ),
    interessecaminhao: new UntypedFormControl(false),
    interesselinhaagro: new UntypedFormControl(false),
    interesselinhaamarela: new UntypedFormControl(false),
    interesseintralogistica: new UntypedFormControl(false),
    mensagem: new UntypedFormControl(""),
  });

  constructor(
    private leadService: LeadService,
    private location: Location,
    private dataLayerService: DataLayerService
  ) {}

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);
  }

  public onTextBox($event: Event): void {
    let textBox = $event.target as HTMLInputElement;
    textBox.value = phoneNumberMask(textBox.value);
  }

  public onCnpjTextBox($event: Event): void {
    let textBox = $event.target as HTMLInputElement;
    textBox.value = cnpjMask(textBox.value);
  }

  public submit() {
    const f = this.form.value;

    const pathname = globalThis?.window?.location.pathname;

    if (pathname.startsWith("/")) {
      const newUrl = pathname.substr(1);
      const hash = globalThis?.window?.location.hash;
      this.identifier = `${newUrl}${hash}`;
    }

    let dados = {
      name: f.nome,
      Empresa: f.razaosocial,
      cnpj: f.cnpj,
      mobile_phone: f.telefone,
      email: f.email,
      interessecaminhao: f.interessecaminhao,
      interesselinhaagro: f.interesselinhaagro,
      interesselinhaamarela: f.interesselinhaamarela,
      interesseintralogistica: f.interesseintralogistica,
      mensagem: f.mensagem,
      identificador: this.identifier,
      token_rdstation: environment.RD_PUBLIC_TOKEN,
    };

    if (this.form.valid) {
      this.sendStatus = "carregando";
      this.subscription = this.leadService
        .enviarComReponderPara(
          dados,
          this.block.sendToEmails?.split("\n"),
          this.block.sendToRdStation,
          this.block.replyTo
        )
        .subscribe((sucesso) => {
          this.sendStatus = sucesso ? "enviado" : "erro";

          if (this.sendStatus == "enviado") {
            this.dataLayerService.formSucesso(this.identifier);
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
