<div class="container-block-card">
  <div class="block flex flex-column pt64" [id]="this.block.blockIdentifier">
    <app-content
      class="content-block-card"
      [content]="content"
      *ngIf="this.block.content as content"
    ></app-content>
    <div>
      <img
        *ngIf="this.block.backgroundImage?.data as imgdata"
        [quality]="'50'"
        [appStrapiimage]="imgdata"
        class="fundo"
        [style.marginTop]="(this.marginFundo | async) + 'px'"
      />
    </div>

    <div class="flex flex-column caixa" #container>
      <div
        class="content-block-card pb64 w-100 flex flex-column"
        style="position: relative"
      >
        <swiper
          [config]="swiperConfigMobile"
          [class.horizontal]="this.block.viewMode == 'horizontal'"
          [class.horizontal-reverse]="
            this.block.viewMode == 'horizontal-reverse'
          "
          [slidesPerView]="this.block.perViewDesktop || 3"
          [autoHeight]="false"
          [spaceBetween]="24"
          [navigation]="{ enabled: true, nextEl: next, prevEl: prev }"
          class="w-100"
        >
          <ng-template
            swiperSlide
            *ngFor="let card of this.block.cards; let i = index"
            style="height: 100%"
          >
            <div style="height: 100%">
              <app-card
                [souOPrimeiroCard]="i == 0"
                (size)="this.marginFundo.next($event)"
                [card]="card"
                [class]="this.block.viewMode"
                class="flex flex-column"
                style="height: 100%; width: 100%"
              ></app-card>
            </div>
          </ng-template>
        </swiper>

        <div
          class="content-block-card next-prev estilo1"
          [style.top]="(this.marginFundo | async) + 'px'"
        >
          <div class="swiper-button-prev" #prev></div>
          <div class="swiper-button-next" #next></div>
        </div>
      </div>

      <div
        class="content-block-card pb64 botao-todos"
        style="position: relative; z-index: 2"
        *ngIf="this.block.links.length"
      >
        <div
          class="flex flex-items-center flex-wrap flex-justify-center"
          style="gap: 16px"
        >
          <app-botao-tipo1
            class="redondo"
            *ngFor="let l of this.block.links"
            [strapilink]="l"
            [cor2]="'var(--primary)'"
            [cor1]="'var(--white)'"
          ></app-botao-tipo1>
        </div>
      </div>

      <div>
        <div
          class="topColor"
          [class]="this.block.topColor"
          [style.marginTop]="(this.marginFundo | async) + 'px'"
        ></div>
      </div>
    </div>
  </div>
</div>
