import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import { Subscription } from "rxjs";
import phoneNumberMask from "src/app/helpers/mask/phoneNumberMask";
import { DataLayerService } from "src/app/services/data-layer.service";
import { LeadService } from "src/app/services/lead.service";
import { environment } from "src/environments/environment";
import { ProdutoPdv } from "src/models/ProdutoPdv";
import { Status } from "src/models/Status";

@Component({
  selector: "app-form-seminovos-tenho-interesse",
  templateUrl: "./form-seminovos-tenho-interesse.component.html",
  styleUrls: ["./form-seminovos-tenho-interesse.component.scss"],
})
export class FormSeminovosTenhoInteresseComponent implements OnInit, OnDestroy {
  @Input() produto?: ProdutoPdv;
  @Input() identifier: string = "formulario-interesse-seminovo";
  @Output() sent = new EventEmitter<any>();

  public sendStatus?: Status;
  public subscription!: Subscription;

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl("", Validators.compose([Validators.required])),
    email: new UntypedFormControl(
      "",
      Validators.compose([Validators.email, Validators.required])
    ),
    telefone: new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(14),
      ])
    ),
    mensagem: new UntypedFormControl(
      this.produto
        ? `Olá, ${this.bomDia()}.\n\nEstou interessado em ${
            this.produto?.descricao
          }.\nGostaria que entrassem em contato comigo.\n\nObrigado!`
        : ""
    ),
  });
  constructor(
    private leadService: LeadService,
    public dataLayerService: DataLayerService
  ) {}

  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form, {
      nome_produto: this.produto?.descricao,
    });
  }

  public onTextBox($event: Event): void {
    let textBox = $event.target as HTMLInputElement;
    textBox.value = phoneNumberMask(textBox.value);
  }

  public submit() {
    const f = this.form?.value;

    const pathname = globalThis?.window?.location.pathname;
    if (pathname.startsWith("/")) {
      this.identifier = "seminovos#formulario-interesse-seminovo";
    }

    let dados = {
      name: f.nome,
      email: f.email,
      mobile_phone: f.telefone,
      mensagem: f.mensagem,
      motivocontato: this.identifier,
      identificador: this.identifier,
      token_rdstation: environment.RD_PUBLIC_TOKEN,
      produto: this.produto?.descricao,
      url: `https://vamos.com.br${globalThis?.window?.location.pathname}`,
    };

    if (this.form?.valid) {
      this.sendStatus = "carregando";
      this.subscription = this.leadService
        .enviarComReponderPara(
          dados,
          [
            "contato@vamosseminovos.octadesk.com",
            "israel.correa@jellyfish.com",
          ],
          true,
          "form.seminovos@grupovamos.com.br"
        )
        .subscribe((sucesso) => {
          this.sendStatus = sucesso ? "enviado" : "erro";

          if (this.sendStatus == "enviado") {
            this.dataLayerService.formSucesso(this.identifier);
          }
        });
    }
  }

  public bomDia(): string {
    let hora = Number(moment().format("HH"));
    if (hora >= 3 && hora < 12) {
      return "bom dia";
    } else if (hora >= 12 && hora < 18) {
      return "boa tarde";
    } else if (hora >= 18 && hora < 3) {
      return "boa noite";
    }
    return "bom dia";
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
