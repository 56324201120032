import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription, map, shareReplay } from "rxjs";
import { GlobalStateService } from "src/app/services/global-state.service";
import { HttpStateService } from "src/app/services/http.state.service";
import { environment } from "src/environments/environment";
import { RelationOneToMany } from "src/models/Relation";
import { Attributes, StrapiRetorno } from "src/models/strapi_retorno";
import {
  IBlockPlanTable,
  IPlanBenefitsLocacao,
  IPlanLocacao,
} from "src/models/zeus";

@Component({
  selector: "app-block-plan-table",
  templateUrl: "./block-plan-table.component.html",
  styleUrls: ["./block-plan-table.component.scss"],
})
export class BlockPlanTableComponent implements OnInit, OnDestroy {
  public isMobile!: boolean;

  public mobileSubscription!: Subscription;

  public planos?: IPlanLocacao[];

  public planosBeneficios: IPlanBenefitsLocacao[] = [];

  public subscription!: Subscription;

  @Input() block!: IBlockPlanTable;

  constructor(
    private httpStateService: HttpStateService,
    private globalStateService: GlobalStateService
  ) {
    this.isMobile = this.globalStateService.isMobile;
  }

  public ngOnInit(): void {
    this.subscription = this.beneficios$.subscribe();
    this.mobileSubscription = this.globalStateService.isMobileChanges.subscribe(
      (actualState) => {
        this.isMobile = actualState;
      }
    );

    this.planos = (
      this.block.plan_locacao as RelationOneToMany<IPlanLocacao>
    ).data
      .sort((a, b) => a.id - b.id)
      .map((x) => x.attributes);

    this.planos.forEach((p) => {
      var pbl =
        p.plan_benefits_locacao as RelationOneToMany<IPlanBenefitsLocacao>;

      pbl.data.forEach((a) => {
        const possuiBeneficio = this.planosBeneficios.filter(
          (b) => b.name == a.attributes.name
        );
        if (possuiBeneficio.length == 0) {
          this.planosBeneficios.push(a.attributes);
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.mobileSubscription?.unsubscribe();
  }

  public beneficios$ = this.httpStateService
    .get<StrapiRetorno<IPlanBenefitsLocacao>>(
      environment.API_URL +
        environment.API_PATH_PLAN_BENEFITS_LOCACAO +
        "?pagination[pageSize]=200"
    )
    .pipe(
      map((x) => x.data),
      shareReplay()
    );

  public planoPossuiBeneficio(
    plano: IPlanLocacao,
    beneficio: Attributes<IPlanBenefitsLocacao>
  ) {
    return !!(
      plano.plan_benefits_locacao as RelationOneToMany<IPlanBenefitsLocacao>
    )?.data.some((pbl) => pbl.id == beneficio.id);
  }

  public planoPossuiBeneficioLocacao(
    plano: IPlanLocacao,
    beneficio: IPlanBenefitsLocacao
  ) {
    let pbl =
      plano.plan_benefits_locacao as RelationOneToMany<IPlanBenefitsLocacao>;

    return !!(
      plano.plan_benefits_locacao as RelationOneToMany<IPlanBenefitsLocacao>
    )?.data.some((pbl) => pbl.attributes.name == beneficio.name);
  }
}
