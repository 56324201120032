import { Component, Input, OnInit } from "@angular/core";
import { ILink } from "src/models/zeus";

@Component({
  selector: "app-botao-whatsapp",
  templateUrl: "./botao-whatsapp.component.html",
  styleUrls: ["./botao-whatsapp.component.scss"],
})
export class BotaoWhatsappComponent implements OnInit {
  constructor() {}

  @Input() strapilink?: ILink;
  @Input() strapiicon?: Arquivo;
  @Input() icon?: string;
  @Input() href?: string;
  @Input() alt?: string;
  @Input() label?: string;

  @Input() cor1?: string = "var(--primary)";
  @Input() cor2?: string = "var(--white)";
  @Input() hvcor1?: string = "var(--white)";
  @Input() hvcor2?: string = "var(--secondary)";

  ngOnInit(): void {
    this.href = `https://wa.me/+5511995140837?text=Estou%20interessado%20no:${globalThis?.window?.location.href}`;
  }
}
