<ng-progress
  #progressBar
  direction="ltr+"
  [speed]="2000"
  [trickleSpeed]="1000"
  [debounceTime]="0"
  [meteor]="true"
  [spinner]="false"
  spinnerPosition="right"
  [thick]="true"
  [fixed]="false"
  [min]="0"
  [max]="100"
  ease="ease"
  color="var(--primary)"
></ng-progress>

<ng-container *ngIf="header$ | async as header">
  <div
    *ngIf="!isMobile"
    class="container flex flex-items-center bg-gray s0"
    style="position: relative; z-index: 4; height: 40px"
  >
    <p
      [appEditorjsparsehtml]="header.welcome"
      class="cor-white b-primary mr32 upc"
    ></p>

    <!-- REMOÇÃO DO SELETOR DE LINGUAGEM POIS O CONTEUDO EM INGLES AINDA NAO FOI CADASTRADO -->

    <!-- <div class="cor-white flex bg-transparent hv-bg-black-translucent flex-items-center languageSelect pl16 pr16">
      <p class=" upc mr8">{{ header.languageselectlabel }}</p>
      <img class="bg-primary brfull" height="16px" width="16px" src="../../../assets/images/bt-arrow.svg" 
        [alt]="header.languageselectlabel">
      <div class="selectBox flex flex-column w-100">
        <button class="hv-bg-black bg-black-translucent" [class.active]="(this.tradutorService.locale$ | async) == 'pt'"
          (click)="changeLang('pt')"><img class="mr8" width="24px" height="24px"
            src="/assets/images/1F1E7-1F1F7.svg"> Português</button>
        <button class="hv-bg-black bg-black-translucent" [class.active]="(this.tradutorService.locale$ | async) == 'en'"
          (click)="changeLang('en')"><img class="mr8" width="24px" height="24px"
            src="/assets/images/1F1FA-1F1F8.svg"> English</button>
      </div>
    </div> -->

    <div class="mlauto flex flex-items-center pipe-between">
      <a
        class="flex flex-justify-center flex-items-center phone s4 fw500 cor-primary brfull ml16 upc hv-cor-primary"
        href="tel:0800 025 4141"
      >
        <img class="mr8" alt="" width="24px" height="24px" />
        0800 025 4141
      </a>
      <a
        class="cor-white brfull ml16 flex upc hv-udl"
        *ngFor="let l of header.fastlinks"
        [appStrapilink]="l"
        >{{ l.label }}</a
      >
    </div>

    <div class="ml24 flex flex-items-center">
      <a
        class="cor-white bg-cor-primary ml8"
        *ngFor="let l of header.socialmedialinks"
        [appStrapilink]="l"
      >
        <img
          height="20px"
          width="20px"
          [appStrapiicon]="l.image?.data"
          class="bg-white hv-bg-primary"
        />
      </a>
    </div>
  </div>

  <div class="container flex bg-black-translucent glass" id="header-menu">
    <a
      [routerLink]="['/']"
      routerLinkActive="router-link-active"
      class="flex"
      style="z-index: 2"
    >
      <img
        *ngIf="!(customLogo$ | async)"
        height="80px"
        width="186px"
        [appStrapiimage]="header.logo.data"
        style="box-shadow: 0 0 16px -8px black"
      />
      <img
        *ngIf="customLogo$ | async as i"
        height="80px"
        width="186px"
        [src]="i"
        style="box-shadow: 0 0 16px -8px black"
      />
    </a>

    <div class="mlauto mrauto flex" *ngIf="!isMobile">
      <ng-container *ngIf="menu$ | async as menu">
        <div
          *ngFor="let menuItem of menu.items.data"
          class="h-100 flex flex-items-center menuItem"
          #g
          (click)="g.classList.add('clicou')"
          (mouseout)="g.classList.remove('clicou')"
        >
          <a
            class="cor-white s2 pa4 menu-item upc h-100 flex flex-items-center hv-udl menu-item"
            *ngIf="menuToILink(menuItem) as mLink"
            [appStrapilink]="mLink"
            [aplicarClassesDeLink]="true"
          >
            {{ menuItem.attributes.title }}
          </a>
          <div
            class="submenu pt32 pb64 bg-white container"
            *ngIf="menuItem.attributes.children.data.length"
          >
            <div
              class="flex flex-column"
              *ngFor="let a of menuItem.attributes.children.data"
            >
              <a
                class="cor-black hv-cor-primary s4 fw600 mb16 suba"
                *ngIf="menuToILink(a) as aLink"
                [appStrapilink]="aLink"
              >
                {{ a.attributes.title }}
              </a>
              <ng-container *ngFor="let b of a.attributes.children.data">
                <a
                  class="cor-gray hv-cor-primary s2 pt8 pb8 subb hv-udl flex flex-items-center"
                  [aplicarClassesDeLink]="true"
                  *ngIf="menuToILink(b) as bLink"
                  [appStrapilink]="bLink"
                  [aplicarClassesDeLink]="true"
                >
                  {{ b.attributes.title }}
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="menu-hamburger flex flex-items-center mlauto" *ngIf="isMobile">
      <button
        (click)="onToggleMobileMenu()"
        class="flex flex-items-center flex-justify-center bg-primary brfull glass"
      >
        <img src="../../../assets/images/icon-menu.svg" alt="Menu" />
      </button>
    </div>
  </div>

  <ng-container *ngIf="breadcrumb$ | async as bcl">
    <ng-container *ngIf="bcl && bcl.length > 1">
      <div
        class="container flex flex-align-center breadcrumb pt8"
        [class.temUmCarrosselNoPrimeiroBlocoDaPrimeiraSection]="
          firstBlockHasCaroussel()
        "
      >
        <a
          class="s2 cor-white fw300 pt8 pb8 pr8 flex flex-items-center hv-udl"
          style="gap: 8px"
          *ngFor="let bc of bcl"
          [routerLink]="bc.url"
          [queryParams]="bc.params"
        >
          <img
            [appStrapiicon]
            icon="simple-arrow.svg"
            width="20px"
            height="20px"
            class="bg-white rt270"
          />
          <p>{{ bc.texto | capitalize : "sentenceCase" }}</p>
        </a>
      </div>
    </ng-container>
  </ng-container>

  <div
    class="menu-full bg-white fechado"
    *ngIf="isMobile"
    [ngClass]="isMobileMenuOpen() ? 'aberto' : 'fechado'"
  >
    <div
      class="topo bg-white flex flex-items-center flex-justify-center pt24 pb32 pl32 pr32"
    >
      <p class="cor-black s5 fw600">menu</p>
      <button
        (click)="onToggleMobileMenu()"
        class="flex flex-items-center flex-justify-center bg-primary brfull botao-menu"
      >
        <img src="../../../assets/images/times.svg" alt="Fechar" />
      </button>
    </div>

    <div class="scroll">
      <div class="pa16">
        <p
          [appEditorjsparsehtml]="header.welcome"
          class="cor-black b-primary upc"
        ></p>

        <a
          class="flex flex-justify-start flex-items-center phone s5 fw500 cor-primary brfull upc hv-cor-primary mt16"
          href="tel:0800 025 4141"
        >
          <img class="mr8" alt="" width="24px" height="24px" />
          0800 025 4141
        </a>

        <!-- REMOÇÃO DO SELETOR DE LINGUAGEM POIS O CONTEUDO EM INGLES AINDA NAO FOI CADASTRADO -->
        <!-- <div class="cor-black mt16">
          <div class="flex flex-row w-100 mb16">
            <p class=" upc mr8">{{ header.languageselectlabel }}</p>
            <img class="bg-primary brfull" height="16px" width="16px" src="../../../assets/images/bt-arrow.svg"
              [alt]="header.languageselectlabel">
          </div>
          <div class="language flex flex-row flex-justify-between flex-wrap w-100">
            <button class="flex flex-items-center cor-white upc hv-bg-black bg-black-translucent w-100 pa8"
              [class.active]="(this.tradutorService.locale$ | async) == 'pt'" (click)="changeLang('pt')"><img
                class="mr8" width="24px" height="24px" src="/assets/images/1F1E7-1F1F7.svg"> Português</button>
            <button class="flex flex-items-center cor-white upc hv-bg-black bg-black-translucent w-100 pa8"
              [class.active]="(this.tradutorService.locale$ | async) == 'en'" (click)="changeLang('en')"><img
                class="mr8" width="24px" height="24px" src="/assets/images/1F1FA-1F1F8.svg"> English</button>
          </div>
        </div> -->
        <!-- <div class="line mt24"></div> -->
      </div>

      <div class="pa16">
        <ng-container *ngIf="menu$ | async as menu">
          <div
            *ngFor="let menuItem of menu.items.data"
            class="h-100 mb16 flex flex-items-start flex-column menuItem"
            #g
            (click)="g.classList.add('clicou')"
            (mouseout)="g.classList.remove('clicou')"
          >
            <a
              (click)="onToggleMobileMenu()"
              class="cor-black s4 fw600 pt8 pb8 upc h-100 flex flex-items-center"
              *ngIf="menuToILink(menuItem) as mLink"
              [appStrapilink]="mLink"
              [aplicarClassesDeLink]="true"
            >
              {{ menuItem.attributes.title }}
            </a>

            <div *ngIf="menuItem.attributes.children.data.length">
              <div
                class="flex flex-column pt8 pb8 pl16"
                *ngFor="let a of menuItem.attributes.children.data"
              >
                <a
                  (click)="onToggleMobileMenu()"
                  class="cor-black hv-cor-primary s4 fw500 mb8 mt8 suba flex flex-items-center"
                  *ngIf="menuToILink(a) as aLink"
                  [appStrapilink]="aLink"
                  [aplicarClassesDeLink]="true"
                >
                  {{ a.attributes.title }}
                </a>
                <ng-container *ngFor="let b of a.attributes.children.data">
                  <a
                    (click)="onToggleMobileMenu()"
                    class="cor-black hv-cor-primary s2 pt8 pb8 pl16 subb flex flex-items-center"
                    *ngIf="menuToILink(b) as bLink"
                    [appStrapilink]="bLink"
                    [aplicarClassesDeLink]="true"
                    >{{ b.attributes.title }}</a
                  >
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="flex flex-column pb16 pl16 pr16">
        <a
          (click)="onToggleMobileMenu()"
          class="cor-black hv-cor-primary flex upc pt8 pb8"
          *ngFor="let l of header.fastlinks"
          [appStrapilink]="l"
          >{{ l.label }}</a
        >
        <div class="line mt16"></div>
      </div>

      <div class="flex flex-row flex-wrap pt8 pb16 pl16 pr16">
        <p class="cor-black s2 w-100 mb16">Siga o grupo vamos:</p>
        <div class="flex w-100" style="gap: 16px">
          <a
            class="cor-black bg-cor-primary"
            *ngFor="let l of header.socialmedialinks"
            [appStrapilink]="l"
          >
            <img
              height="20px"
              width="20px"
              [appStrapiicon]="l.image?.data"
              class="bg-black hv-bg-primary"
            />
          </a>
        </div>
        <div class="line mt24"></div>
      </div>
    </div>
  </div>
</ng-container>
