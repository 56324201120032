import { DOCUMENT } from "@angular/common";
import {
  Component,
  Input,
  Inject,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GlobalStateService } from "src/app/services/global-state.service";
import { ICarousel } from "src/models/zeus";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit {
  @Output() displayForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public carousel!: ICarousel;
  @Input() firstSlide = false;
  @Input() primeiroItemDoPrimeiroBloco = false;
  @Input() FormStatus: any;
  @Input() formularioFixo: boolean | undefined;
  public isMobileLayout = false;
  public isTabletLayout = false;
  public isDesktopLayout = false;
  public FormStatusItem = false;
  public isLocacao = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private globalStateService: GlobalStateService
  ) {}

  public ngOnInit(): void {
    let myRoute = this.route.snapshot;
    if (myRoute?.url[0]?.path) {
      const path = myRoute.url[0].path;
      if (path == "locacao") this.isLocacao = true;
    }

    this.onResize();
  }

  @HostListener("window:resize") onResize() {
    const realScreenWidth =
      globalThis?.window?.screen.width /
      (globalThis?.window?.devicePixelRatio ?? 1);
    if (realScreenWidth <= 767) {
      this.isMobileLayout = true;
      this.isTabletLayout = false;
      this.isDesktopLayout = false;
    } else if (realScreenWidth >= 768 && realScreenWidth <= 991) {
      this.isTabletLayout = true;
      this.isMobileLayout = false;
      this.isDesktopLayout = false;
    } else {
      this.isDesktopLayout = true;
      this.isMobileLayout = false;
      this.isTabletLayout = false;
    }
  }

  public handleClickBanner() {
    this.FormStatusItem = true;
    this.displayForm.emit(true);
  }
}
