import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: "root",
})
export class DataLayerService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  push(o: Object) {
    // alert(JSON.stringify(o))
    if (isPlatformBrowser(this.platformId)) {
      globalThis?.window?.dataLayer?.push(o);
    }
  }

  formFields(identifier: string, form: FormGroup, extraFields = {}) {
    Object.keys(form.controls).forEach((controlProp) => {
      const control = form.controls[controlProp];
      control.valueChanges.pipe(debounceTime(2000)).subscribe((valor) => {
        if (valor !== "") {
          this.push({
            event: "preencher_campo_form",
            nome_campo: controlProp,
            nome_form: identifier,
            ...extraFields,
          });
        }
      });
    });
  }

  formSucesso(identifier: string, extraFields = {}) {
    this.push({
      event: `${identifier}`,
      nome_form: identifier,
      ...extraFields,
      // 'nome_produto': 'string_value'
    });
  }

  teste() {
    alert();
  }
}
