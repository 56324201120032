import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { switchMap, map, Subscription } from "rxjs";
import { GlobalStateService } from "src/app/services/global-state.service";
import { HttpStateService } from "src/app/services/http.state.service";
import { MenuService } from "src/app/services/menu.service";
import { TradutorService } from "src/app/services/tradutor.service";
import { environment } from "src/environments/environment";
import { Raiz } from "src/models/Menus";
import { StrapiRetornoUnico } from "src/models/strapi_retorno";
import { IFooter, ILink } from "src/models/zeus";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
  public isMobile!: boolean;
  public subscription!: Subscription;

  constructor(
    private tradutorService: TradutorService,
    private httpStateService: HttpStateService,
    private menuService: MenuService,
    private globalStateService: GlobalStateService
  ) {
    this.isMobile = this.globalStateService.isMobile;
  }

  public ngOnInit() {
    this.subscription = this.globalStateService.isMobileChanges.subscribe(
      (actualState) => {
        this.isMobile = actualState;
      }
    );
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public menu$ = this.menuService.menuFooter$.pipe();

  public footer$ = this.tradutorService.locale$
    .pipe(
      switchMap((idioma) => {
        const url =
          environment.API_URL +
          environment.API_PATH_FOOTER +
          "?populate=deep&locale=" +
          idioma;
        return this.httpStateService.get<StrapiRetornoUnico<IFooter>>(url);
      })
    )
    .pipe(map((x) => x.data.attributes));

  public menuToILink(sub: Raiz): Partial<ILink> {
    return {
      href: sub.attributes.url,
      target: sub.attributes.target as any,
      title: sub.attributes.title,
    };
  }
}
